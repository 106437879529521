import RayloLogo from '../../Icons/RayloLogo';

export function MobileAppNavBar() {
  return (
    <div
      data-testid="mobile-app-nav-bar"
      className="inline-flex h-20 w-full items-center justify-between bg-blue-500 px-6 py-2"
    >
      <RayloLogo data-testid="raylo-logo" className="text-pink-300" />
    </div>
  );
}
