import styled from 'styled-components';
import { palette } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';
import { BREAKPOINTS, CSS_FONT_WEIGHT, TAILWIND_BREAKPOINTS } from '../../constants';

export const AccountHeader = styled.header`
  padding: ${px2Rem(10)} ${px2Rem(35)};
  background-color: ${palette.blue[500]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-height: ${px2Rem(80)};

  @media (max-width: ${px2Rem(BREAKPOINTS.smallDesktop)}) {
    padding: ${px2Rem(20)} ${px2Rem(24)};
  }
`;

export const AccountHeaderNav = styled.nav<{ $visible: boolean }>`
  @media (max-width: ${TAILWIND_BREAKPOINTS.lg}px) {
    position: absolute;
    z-index: 5;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    max-height: ${({ $visible }) => ($visible ? '700px' : '0')};
    background-color: ${palette.blue[500]};
    transition: max-height 0.65s ease-in-out;
  }

  .nav-links {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media (max-width: ${TAILWIND_BREAKPOINTS.lg}px) {
      display: block;
      padding-left: ${px2Rem(20)};
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(-40px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  li:last-of-type {
    display: flex;
    align-items: center;
  }

  .nav-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    animation: ${({ $visible }) => ($visible ? 'fadeInUp 0.6s ease-in' : 'none')};

    &.active,
    &:hover {
      a,
      button {
        position: relative;
        height: (100% - ${px2Rem(4)});
        display: block;
        width: fit-content;
        font-weight: ${CSS_FONT_WEIGHT.vars.bold};

        &::after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          right: 0;
          height: ${px2Rem(4)};
          background-color: ${palette.pink[300]};
        }
      }
    }

    &:not(:last-of-type) {
      margin-right: ${px2Rem(40)};

      @media (max-width: 1200px) {
        margin-right: ${px2Rem(32)};
      }

      @media (max-width: 900px) {
        margin-right: 0;
      }
    }

    @media (max-width: 900px) {
      display: block;
      margin-bottom: ${px2Rem(20)};
      padding-left: 0;
    }
  }
`;

export const NavLink = styled.a`
  font-size: 0.875em;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  color: ${palette.white};
  letter-spacing: ${px2Rem(0.9)};
  line-height: 4.14;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export const NavToggle = styled.div`
  width: ${px2Rem(40)};
  height: ${px2Rem(40)};
  border-radius: ${px2Rem(1)};
  background-color: transparent;
  cursor: pointer;
`;

export const NavToggleIcon = styled.div<{ $active: boolean }>`
  ${({ $active }) => `
    position: relative;
    height: ${px2Rem(40)};
    width: ${px2Rem(40)};

    span,
    span:before,
    span:after {
      cursor: pointer;
      height: ${px2Rem(2)};
      width: ${px2Rem(20)};
      background: ${palette.white};
      position: absolute;
      display: block;
      content: '';
      transition: all 0.35s;
      top: 50%;
      transform: translate(50%, 50%);
    }

    span:before {
      top: ${$active ? '0' : '-6px'};
      transform: ${$active ? 'rotate(45deg)' : 'none'};
    }

    span:after {
      bottom: ${$active ? 'unset' : '-6px'};
      top: ${$active ? '6px' : 'unset'};
      transform: ${$active ? 'translateY(-6px) rotate(-45deg)' : 'none'};
    }

    span {
      background-color: ${$active ? 'transparent' : palette.white};
    }
  `}
`;
