import { useCallback, useEffect, useRef, useState } from 'react';
import {
  StyledAccountListItem,
  StyledAccountSwitcherButton,
  StyledAccountSwitcherToggle,
  StyledAccountsList,
  StyledAccountsMenu,
  StyledAccountsMenuWrapper,
} from './AccountSwitcherDropdown.styles';
import { px2Rem } from '../../utils/px2Rem';
import type { Account } from '../../types/navData.types';
import { Typography } from '../../foundations/Typography/Typography';
import RayloModal from '../../designSystem/RayloModal';

type AccountSwitcherDropdownProps = {
  dataTestId?: string;
  name: string;
  email: string;
  activeAccount: Account;
  accounts: Account[];
  addBusinessAccountOnClick: () => void;
  logoutOnClick: () => void;
};

const AccountSwitcherDropdown = ({
  dataTestId,
  name,
  email,
  accounts,
  activeAccount,
  addBusinessAccountOnClick,
  logoutOnClick,
}: AccountSwitcherDropdownProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [newBusinessModalOpen, setNewBusinessModalOpen] = useState(false);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (!isOpen || !e.target || !menuRef.current) return;
      if (!menuRef.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    },
    [isOpen],
  );

  useEffect(() => {
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  const changeAccount = (accountOnClick: () => void) => {
    setIsOpen(false);
    accountOnClick();
  };

  return (
    <StyledAccountsMenuWrapper ref={menuRef} data-testid={dataTestId}>
      <StyledAccountSwitcherToggle
        data-testid={`${dataTestId}-toggle`}
        $letter={activeAccount.name?.[0] ?? ''}
        $active={isOpen}
        onClick={() => setIsOpen((open) => !open)}
      />
      <StyledAccountsMenu
        data-testid={`${dataTestId}-accounts-menu`}
        $top={34}
        $height={293 + accounts.length * 64}
        $active={isOpen}
      >
        <Typography bold variant="h4" className="px-6 pb-2 pt-6">
          {name}
        </Typography>
        <Typography
          variant="body2"
          className="text-charcoal-400 border-bottom border-charcoal-200 px-6 pb-6"
        >
          {email}
        </Typography>
        <Typography bold style={{ padding: px2Rem(24), margin: 0 }}>
          Accounts
        </Typography>
        <StyledAccountsList>
          {accounts.map((account, index) => (
            <StyledAccountListItem
              key={index}
              $active={account.id === activeAccount.id}
              $isBusiness={account.isBusiness}
              $letter={account.name?.[0] ?? ''}
              onClick={() => changeAccount(account.onClick)}
              data-testid={`${dataTestId}-account-${index}-${account.isBusiness ? 'business' : 'personal'}`}
            >
              <Typography
                data-testid={`${dataTestId}-name${account.id === activeAccount.id ? '-active' : ''}`}
                variant="body2"
                medium
                className="w-max"
              >
                {account.name}
              </Typography>
            </StyledAccountListItem>
          ))}
        </StyledAccountsList>
        <StyledAccountSwitcherButton onClick={() => setNewBusinessModalOpen(true)}>
          <Typography variant="body2" medium>
            Add a new business
          </Typography>
        </StyledAccountSwitcherButton>
        <StyledAccountSwitcherButton data-testid="logout-button" onClick={logoutOnClick}>
          <Typography variant="body2" medium>
            Logout
          </Typography>
        </StyledAccountSwitcherButton>
      </StyledAccountsMenu>
      {newBusinessModalOpen && (
        <RayloModal
          dataTestId="new-business-modal"
          modalOpen={newBusinessModalOpen}
          handleCloseModal={() => setNewBusinessModalOpen(false)}
          title="Add a new business"
          copy={['To add a new business, you must choose a product and checkout.']}
          primaryButtonText="Choose a product"
          primaryButtonOnClick={() => {
            addBusinessAccountOnClick();
            setNewBusinessModalOpen(false);
          }}
          secondaryButtonText="Cancel"
          secondaryButtonOnClick={() => setNewBusinessModalOpen(false)}
        />
      )}
    </StyledAccountsMenuWrapper>
  );
};

export default AccountSwitcherDropdown;
