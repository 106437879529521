import React, { useState } from 'react';
import { ExistingCustomerNavBarProps } from './ExistingCustomerNavBar.types';
import {
  AccountHeader,
  AccountHeaderNav,
  NavLink,
  NavToggle,
  NavToggleIcon,
} from './ExistingCustomerNavBar.styles';
import { ASSETS_SVGS_BASE_URL, TAILWIND_BREAKPOINTS } from '../../constants';
import { useMediaLayout } from 'use-media';
import AccountSwitcherDropdown from '../../components/AccountSwitcherDropdown/AccountSwitcherDropdown';
import { Typography } from '../../foundations/Typography/Typography';
import { px2Rem } from '../../utils/px2Rem';

export const ExistingCustomerNavBar = ({
  dataTestId,
  onLogout,
  logoLink,
  links,
  name,
  email,
  activeAccount,
  accounts,
  addBusinessAccountOnClick,
}: ExistingCustomerNavBarProps) => {
  const [mobileNavVisible, setMobileNavVisible] = useState(false);
  const isMobile = useMediaLayout({ maxWidth: TAILWIND_BREAKPOINTS.lg });

  return (
    <AccountHeader data-testid={dataTestId}>
      <div style={{ display: 'flex', alignItems: 'center', gap: px2Rem(12) }}>
        <a href={logoLink} title="My Account">
          <img src={`${ASSETS_SVGS_BASE_URL}/raylo-logo.svg`} alt="Raylo Logo" />
        </a>
        {activeAccount.isBusiness && (
          <Typography variant="fineprint" className="text-white">
            Business
          </Typography>
        )}
      </div>
      <AccountHeaderNav $visible={mobileNavVisible} data-testid={`${dataTestId}-nav`}>
        <ul className="nav-links">
          {links.map(({ title, href }) => (
            <li
              key={title}
              className={`nav-item ${window.location.pathname === href ? 'active' : ''}`}
            >
              <NavLink href={href} title={title}>
                {title}
              </NavLink>
            </li>
          ))}
          {!isMobile && (
            <li>
              <AccountSwitcherDropdown
                dataTestId="account-switcher-dropdown"
                name={name}
                email={email}
                accounts={accounts}
                activeAccount={activeAccount}
                logoutOnClick={onLogout}
                addBusinessAccountOnClick={addBusinessAccountOnClick}
              />
            </li>
          )}
        </ul>
      </AccountHeaderNav>

      {isMobile && (
        <div style={{ display: 'flex', alignItems: 'center', gap: px2Rem(14) }}>
          <div onClick={() => setMobileNavVisible(false)}>
            <AccountSwitcherDropdown
              dataTestId="account-switcher-dropdown"
              name={name}
              email={email}
              accounts={accounts}
              activeAccount={activeAccount}
              logoutOnClick={onLogout}
              addBusinessAccountOnClick={addBusinessAccountOnClick}
            />
          </div>
          <NavToggle
            onClick={() => setMobileNavVisible((visible) => !visible)}
            data-testid={`${dataTestId}-nav-toggle-${mobileNavVisible ? 'open' : 'closed'}`}
          >
            <NavToggleIcon $active={mobileNavVisible}>
              <span />
            </NavToggleIcon>
          </NavToggle>
        </div>
      )}
    </AccountHeader>
  );
};
