import * as React from 'react';
import { SVGProps } from 'react';
const RayloLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={140} height={32} fill="none" {...props}>
    <path
      fill="currentColor"
      d="M18.684 9.978c0-3.377-2.07-5.206-5.91-5.206h-8.8v10.412h8.8c3.84 0 5.91-1.829 5.91-5.206Zm-4.276 8.325 7.075 11.995v.594H17.25L10.3 18.942H3.952v11.95H0v-11.95h3.795v-3.758H0V1.013h13.423c5.35 0 9.359 3.546 9.359 8.83 0 5.341-3.583 8.079-8.374 8.46ZM39.514 5.456h-.515L33.86 18.82h10.658L39.514 5.456ZM53.07 30.298v.594h-3.974l-3.325-8.617H32.618l-3.19 8.617h-3.975v-.594L37.107 1.013h4.31L53.07 30.298ZM78.817 2.284 63.972 31.48h-4.097v-.594l6-11.355h-4.007L52.8 2.284V1.6h4.142l9.146 17.593L74.764 1.6h4.053v.684ZM101.863 27.178v3.714H81.589V1.012h3.963v26.166h16.311ZM128.991 16c0-7.046-4.959-12.163-11.262-12.163-6.303 0-11.262 5.117-11.262 12.163 0 7.125 4.959 12.163 11.262 12.163 6.303 0 11.262-5.038 11.262-12.163Zm4.108 0c0 9.346-7.075 16-15.359 16-8.374 0-15.359-6.654-15.359-16 0-9.436 6.985-16 15.359-16 8.284-.011 15.359 6.564 15.359 16ZM139.333 26.75h-4.143v4.05h4.143v-4.05Z"
    />
  </svg>
);
export default RayloLogo;
