import Head from 'next/head';
import { useAppContext } from '../../hooks/useAppContext';
import { PageMetaProps } from './types';
import { ASSETS_IMAGES_BASE_URL } from 'uibook';

export const PageMeta = ({
  title,
  metaTitle,
  metaDescription,
  metaImage,
  canonicalLink,
  noIndex,
}: PageMetaProps) => {
  const { isRayloPay } = useAppContext();

  return (
    <Head>
      <title>{title}</title>
      {(isRayloPay || noIndex) && <meta name="robots" content="noindex,nofollow" />}

      {metaTitle &&
        ['og:title', 'twitter:title'].map((property) => (
          <meta key={property} content={metaTitle} property={property} />
        ))}

      {metaDescription && (
        <>
          <meta content={metaDescription} name="description" />
          {['og:description', 'twitter:description'].map((property) => (
            <meta key={property} content={metaDescription} property={property} />
          ))}
        </>
      )}

      {metaImage &&
        ['og:image', 'twitter:image'].map((property) => (
          <meta key={property} content={metaImage} property={property} />
        ))}

      <meta property="og:type" content="website" />
      <link rel="icon" href={`${ASSETS_IMAGES_BASE_URL}/favicon.ico`} />
      {canonicalLink && <link rel="canonical" href={canonicalLink} />}
    </Head>
  );
};
